import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import VerifyEmail from './VerifyEmail';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Find from './Find';

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/find" element={<Find />} />
        <Route path='/careers' element={<ExternalRedirect to='https://viniciti.notion.site/ViniCiti-Careers-efed369b6976406f9e58d15702e8985d' />} />
        <Route path='/blog' element={<ExternalRedirect to='https://medium.com/@viniciti' />} />
        <Route path='/guidelines' element={<ExternalRedirect to='https://viniciti.notion.site/ViniCiti-Community-Guidelines-e8d990313d2b401a8c475df4c56727fd' />} />
        <Route path='/terms' element={<ExternalRedirect to='https://viniciti.notion.site/ViniCiti-Terms-of-Use-de9307a118234e63916413cd4bd67a39' />} />
        <Route path='/privacy' element={<ExternalRedirect to='https://viniciti.notion.site/ViniCiti-Privacy-Policy-5da749d7d66942899c96002b846898cd' />} />
        {/* <Route path="/:username" element={<Contact />} /> */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
