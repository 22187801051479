import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Find.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Find() {
  const query = useQuery();
  const email = query.get('email');
  const phonenumber = query.get('phonenumber');
  const token = query.get('token');
  const type = query.get('type');

  useEffect(() => {

    // find();
  }, [email, phonenumber, token, type]);

  return (
    <div className="App">
      <div id="page" className="page contacts-section">
        <header id="header" className="header">
          <nav className="navbar fixed-top navbar-expand-lg dark-menu navbar-dark hover-menu hidden-nav">
            <div className="container">
              
              <a href="#hero-2" className="navbar-brand logo-black"><img src="images/logo.png" height="30" alt="VINICITI"/></a>
              <a href="#hero-2" className="navbar-brand logo-white"><img src="images/logo-white.png" height="30" alt="VINICITI"/></a>
              
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-bar-icon"><i className="fas fa-bars"></i></span>
                </button>
                
                <div id="navbarSupportedContent" className="collapse navbar-collapse">				  			
                  <ul className="navbar-nav ml-auto">
                        <li className="nav-item nl-simple"><a target="_blank" rel="noopener noreferrer" href="https://viniciti.notion.site/ViniCiti-Privacy-Policy-5da749d7d66942899c96002b846898cd" className="nav-link">Privacy</a></li>
                        <li className="nav-item nl-simple"><a target="_blank" rel="noopener noreferrer" href="https://viniciti.notion.site/ViniCiti-Terms-of-Use-de9307a118234e63916413cd4bd67a39" className="nav-link">Terms</a></li> 
                        <li className="nav-item nl-simple"><a target="_blank" rel="noopener noreferrer" href="https://viniciti.notion.site/ViniCiti-Community-Guidelines-e8d990313d2b401a8c475df4c56727fd" className="nav-link">Guidelines</a></li>
                    </ul>
                    
                    <span className="navbar-text">						      															
                  <a href="#content-10" className="btn btn-purple purple-hover">Contact Us</a>
                  </span>	

                </div>


            </div>
          </nav>
        </header>
        
        <section id="contacts-5" className="bg-dark wide-70 b-bottom division">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title mb-70">
                  <h2 className="h2-xs white-color">Find Your Perfect Match!</h2>
                  <p className="p-xl">
                  Share what you’re looking for in your perfect match, and we’ll find someone who fits your description. Complete this form with your preferences, and we’ll send you compatible matches.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                <div className="form-holder">
                  <form name="contactform" className="row contact-form">
                    <div id="input-name" className="col-lg-12">
                      <p className="p-lg white-color">Your Name: </p>
                      <span>Please enter your real name: </span>
                      <input type="text" name="name" className="form-control name" placeholder="Your Name*" />
                    </div>
                    <div id="input-name" className="col-lg-12">
                      <p className="p-lg white-color">Your Username: </p>
                      <span>Reserve a unique username: </span>
                      <input type="text" name="name" className="form-control name" placeholder="Your Username*" />
                    </div>
                    <div id="input-email" className="col-lg-12">
                      <p className="p-lg white-color">Your Email Address: </p>
                      <span>Please carefully check your email address for accuracy</span>
                      <input type="text" name="email" className="form-control email" placeholder="Email Address*" />
                    </div>
                    <div id="input-phone" className="col-lg-12">
                      <p className="p-lg white-color">Your Phone Number: </p>
                      <span>Please carefully check your Phone Number</span>
                      <input type="text" name="phone" className="form-control email" placeholder="Phone Number*" />
                    </div>
                    <div id="input-message" className="col-lg-12 input-message">
                      <p className="p-lg white-color">Explain your match in details: </p>
                      <span>Tell Us About Your Perfect Match, location, age, type of job. Be VERY precise!</span>
                      <textarea className="form-control message" name="message" rows="6" placeholder="Late 20's, working in the financial space, lives in Abuja..."></textarea>
                    </div>
                    <div className="col-lg-12 mt-15 form-btn text-right">
                      <button type="submit" className="btn btn-purple purple-hover submit">Get Me a Match! 🚀🚀🚀 😊</button>
                    </div>
                    <div className="col-lg-12 contact-form-msg">
                      <span className="loading"></span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <footer id="footer-2" className="footer division">
        <div className="container">
          
          <div className="row">
            
            <div className="col-md-3">
              <div className="footer-info mb-40">
                
                <img src="images/logo-sm.png" height="50" alt="VINICITI"/>
              
              </div>	
            </div>
            
            <div className="col-sm-4 col-md-3 col-xl-2">
              <div className="footer-links mb-40">
                <ul className="foo-links clearfix">
                  <li><a href="mailto:info@viniciti.com">Help & Support</a></li>
                  <li><a href="https://medium.com/@viniciti">Our Blog</a></li>
                  <li><a href="https://viniciti.notion.site/ViniCiti-Careers-efed369b6976406f9e58d15702e8985d">Careers</a></li>
                </ul>
              </div>
            </div>
            
            <div className="col-sm-4 col-md-3 col-xl-2">
              <div className="footer-links mb-40">
                <ul className="foo-links clearfix">	
                  <li><a href="https://viniciti.notion.site/ViniCiti-Privacy-Policy-5da749d7d66942899c96002b846898cd">Privacy Policy</a></li>
                  <li><a href="https://viniciti.notion.site/ViniCiti-Terms-of-Use-de9307a118234e63916413cd4bd67a39">Terms</a></li>
                  <li><a href="https://viniciti.notion.site/ViniCiti-Community-Guidelines-e8d990313d2b401a8c475df4c56727fd">Guidelines</a></li>
                </ul>
              </div>
            </div>
            
            <div className="col-sm-4 col-md-3 offset-xl-2">
              <div className="footer-store-badges text-right mb-40">
                
                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/viniciti/id1584689774" className="store">
                  <img className="appstore-original" src="images/appstore.png" alt="appstore" />
                </a>
                
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.viniciti.viniciti" className="store">
                  <img className="googleplay-original" src="images/googleplay.png" alt="googleplay" />
                </a> 
                    
              </div>
            </div>


          </div>
          

          <div className="bottom-footer">
            <div className="row d-flex align-items-center">
              

              <div className="col-md-6">
                <div className="footer-copyright">
                  <p>&copy; 2024 VINICITI. All Rights Reserved</p>
                </div>
              </div>
              

              <div className="col-md-6">
                <ul className="bottom-footer-list text-right clearfix">
                  <li><a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/company/viniciti">LinkedIn</a></li>	
                  <li><a target="_blank" rel="noopener noreferrer" href="https://x.com/vinicitiapp">X</a></li>	
                  <li className="last-li"><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/viniciti">Instagram</a></li>
                </ul>
              </div>


            </div>
          </div>


        </div>								
        </footer>
      </div>
    </div>
  );
}

export default Find;
