import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './VerifyEmail.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VerifyEmail() {
  const [isVerified, setIsVerified] = useState(null);
  const [message, setMessage] = useState("VERIFYING");
  const query = useQuery();
  const email = query.get('email');
  const phonenumber = query.get('phonenumber');
  const token = query.get('token');
  const type = query.get('type');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const clientId = "mobile";
        const clientSecret = "Vini_MobB1l3aPp_seCret";

        const response = await fetch('https://appapi.viniciti.com/Onboarding/ConfirmEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clientId, clientSecret, type, email, phonenumber, token })
        });

		const data = await response.json();

        if (response.ok && data.isSuccessful) {
			setIsVerified(true);

			if((data.message !== null) && 
				(data.message !== "")) {
				setMessage(data.message);
			}
			else {
				setMessage("Email verified successfully!");
			}
        } else {
          // Handle other status codes if needed
          console.error('Failed to verify email', response.status);
					  
			if((data.message !== null) && 
				(data.message !== "")) {
				setMessage(data.message);
			}
			else {
				setMessage("We are verifying your email...");
			}
        }
      } catch (error) {
        console.error('Error verifying email', error);
      }
    };

    verifyEmail();
  }, [email, phonenumber, token, type]);

  return (
    <div className="App">
      {isVerified === true ? (
        <div id="page" className="page">
          <div className="bg-dark verificationcard">
            <div className='verificationicon'>
              <i className="checkmark">✓</i>
            </div>
            <h1 className='green-color'>Success</h1>
            <p>{message}</p>
          </div>
        </div>
      ) 
      : isVerified === false ? (
        <div id="page" className="page">
          <div className="bg-dark verificationcard">
            <h1 className='red-color'>Invalid Verification Link</h1>
            <p>{message}</p>
          </div>
        </div>
      )
    : (
      <div id="page" className="page">
        <div className="bg-dark verificationcard">
          {/* <h1 className='red-color'>Invalid Verification Link</h1> */}
          <p>{message}</p>
        </div>
      </div>
    )}
    </div>
  );
}

export default VerifyEmail;
