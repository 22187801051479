import './App.css';

function App() {
  return (
    <div className="App">
      
		<div id="page" className="page">
      
			<header id="header" className="header">
				<nav className="navbar fixed-top navbar-expand-lg dark-menu navbar-dark hover-menu hidden-nav">
					<div className="container">
            
				 		<a href="#hero-2" className="navbar-brand logo-black"><img src="images/logo.png" height="30" alt="header-logo"/></a>
				 		<a href="#hero-2" className="navbar-brand logo-white"><img src="images/logo-white.png" height="30" alt="header-logo"/></a>
            
				 		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				   		 	<span className="navbar-bar-icon"><i className="fas fa-bars"></i></span>
				  		</button>
              
				  		<div id="navbarSupportedContent" className="collapse navbar-collapse">				  			
					   		<ul className="navbar-nav ml-auto">
          						<li className="nav-item nl-simple"><a target="_blank" rel="noopener noreferrer" href="https://viniciti.notion.site/ViniCiti-Privacy-Policy-5da749d7d66942899c96002b846898cd" className="nav-link">Privacy</a></li>
                      <li className="nav-item nl-simple"><a target="_blank" rel="noopener noreferrer" href="https://viniciti.notion.site/ViniCiti-Terms-of-Use-de9307a118234e63916413cd4bd67a39" className="nav-link">Terms</a></li> 
                      <li className="nav-item nl-simple"><a target="_blank" rel="noopener noreferrer" href="https://viniciti.notion.site/ViniCiti-Community-Guidelines-e8d990313d2b401a8c475df4c56727fd" className="nav-link">Guidelines</a></li>
					      	</ul>
                  
					      	<span className="navbar-text">						      															
								<a href="https://forms.gle/DTrBywNdGwLuNtkp8" className="btn btn-purple purple-hover">Get Started</a>
						    </span>	

					    </div>


					</div>
				</nav>
			</header>
      
			<section id="hero-2" className="bg-scroll hero-section division">	
				<div className="container">
					<div className="row d-flex align-items-center">
            
						<div className="col-md-7 col-xl-6">
							<div className="hero-2-txt pc-15 wow fadeInRight" data-wow-delay="0.6s">
                
								<div className="hero-app-logo">	
									<div className="text-center">							
										<img className="img-fluid" src="images/hero-logo-yellow.png" width="85" height="85" alt="hero-app-logo"/>
										{/* <h5 className="h5-lg white-color">VINICITI</h5> */}
									</div>
								</div>
                
								<h2 className="white-color">Hire Experienced Software Developers</h2>
                
								<p className="p-xl white-color">Connect with us for Expert Software Development Services: Skilled Developers, Testers, and Project Managers Dedicated to Turning Your Business Goals into Live Products—Fast.
								</p>
                									
								<div className="stores-badge">
									
									<a href="https://forms.gle/DTrBywNdGwLuNtkp8" className="btn btn-dark dark-hover store">Get In Touch</a>
									<a href="https://calendly.com/viniciti/30min" className="btn btn-dark dark-hover store">Schedule A Call</a>
									{/* <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/viniciti/id1584689774" className="store">
										<img className="appstore-original" src="images/appstore.png" alt="appstore-badge" />
									</a> */}
                  
									{/* <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.viniciti.viniciti" className="store">
										<img className="googleplay-original" src="images/googleplay.png" alt="googleplay-badge" />
									</a> */}
                  
								</div>
		
							</div>
						</div>
            
						<div className="col-md-5 col-xl-6">
							<div className="hero-2-img wow fadeInLeft" data-wow-delay="0.4s">	
								<img className="img-fluid" src="images/hero-2-img.png" alt="hero-image" />
							</div>
						</div>

						
					</div>
				</div>
			</section>
      
			{/* <div className="gb-holder"><div className="container"><div className="grey-border w-90"></div></div></div> */}
      
			{/* <section id="content-5" className="wide-60 content-section division">
				<div className="container">
          
					<div id="c5-top" className="pb-50">
						<div className="row d-flex align-items-center m-row">
              
							<div className="col-md-5 col-lg-6 m-bottom">
								<div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.4s">
									<img className="img-fluid" src="images/image-20.png" alt="content-image"/>
								</div>
							</div>
              
							<div className="col-md-7 col-lg-6 m-top">
								<div className="txt-block right-column pc-35 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                  
									<h3 className="h3-xl txt-700 white-color">Quality connections comes first</h3>
                  
									<ul className="txt-list grey-color mb-10">

										<li className="list-item">
											<i className="fas fa-angle-right"></i>
											<p className="p-md"><b>Manual Profile Review:</b> Every profile is carefully reviewed by our team to ensure authenticity and foster meaningful connections.
                      </p>
										</li>

										<li className="list-item">
											<i className="fas fa-angle-right"></i>
											<p className="p-md"><b>Thoughtful Matching:</b> Our advanced algorithms prioritize compatibility to help you find your perfect match.
                      </p>
										</li>

										<li className="list-item">
											<i className="fas fa-angle-right"></i>
											<p className="p-md"><b>Comprehensive Profiles:</b> Get to know potential matches with in-depth profiles that go beyond the surface.
											</p>
										</li>

                    <li className="list-item">
                      <i className="fas fa-angle-right"></i>
                      <p className="p-md"><b>User-Friendly Interface:</b> Enjoy a seamless and intuitive experience as you navigate the app.
                      </p>
                    </li>

                    <li className="list-item">
                      <i className="fas fa-angle-right"></i>
                      <p className="p-md"><b>Secure and Private:</b> Your safety and privacy are our top priorities.
                      </p>
                    </li>

									</ul>

								</div>
							</div>


						</div>
					</div>
          
			 		<div id="c5-bottom">
			 			<div className="row d-flex align-items-center">
              
							<div className="col-md-7 col-lg-6">
								<div className="txt-block left-column pc-35 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                  
									<h3 className="h3-xl txt-700 white-color">How It Works</h3>
                  
									<p className="p-md grey-color"><b>Step 1: Sign Up</b><br/> Create your account with a few simple steps. Our user-friendly interface makes the registration process quick and easy.
									</p>
									<p className="p-md grey-color"><b>Step 2: Profile Review</b><br/> Our team manually reviews each profile to ensure authenticity and quality, fostering a community of genuine connections.
									</p>
									<p className="p-md grey-color"><b>Step 3: Thoughtful Matching</b><br/> Our advanced matching algorithm prioritizes compatibility, helping you find potential matches who share your values and interests.
									</p>

								</div>
							</div>
              
							<div className="col-md-5 col-lg-6">
								<div className="img-block right-column pc-25 mb-40 wow fadeInLeft" data-wow-delay="0.4s">
									<img className="img-fluid" src="images/image-22.png" alt="content-image"/>
								</div>
							</div>


						</div>
			 		</div>


				</div>
			</section> */}
      
    <section id="features-3" className="features-section division">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="section-title mb-80">
              <h2 className="h2-xs txt-700 white-color">Why Us?</h2>
              <p className="p-xl">
				Technology moves fast, and with AI advancing quickly, you need more than just the basics. We specialize in building scalable, future-proof software solutions using the latest technologies—and we do it quickly. Our team delivers fast, high-quality results, ensuring your architecture is built to grow with your business and stay ahead of the curve. Let’s turn your vision into reality, fast!
              </p>
            </div>
          </div>
        </div>
		
		<div className="gb-holder"><div className="container"><div className="grey-border w-90"></div></div></div>
		
        <div className="fbox-3-holder pc-30">
          <div className="row wide-60">
            <div className="col-md-6">
              <div className="fbox-3 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                <div className="fbox-3-ico grey-color ico-65">
                  <span className="flaticon-code"></span>
                </div>
                <div className="fbox-3-txt">
                  <h5 className="h5-sm txt-700 white-color">Software Dev Services</h5>
                  <p className="grey-color">
					We don’t just code—we build tailored software solutions from the ground up, ensuring every step is crafted to meet your needs. Our team handles everything from development to thorough QA testing, delivering high-quality, scalable applications that work seamlessly.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="fbox-3 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                <div className="fbox-3-ico grey-color ico-65">
                  <span className="flaticon-development"></span>
                </div>
                <div className="fbox-3-txt">
                  <h5 className="h5-sm txt-700 white-color">IT Consulting Services</h5>
                  <p className="grey-color">
					We’ll help you navigate the ever-changing tech landscape, giving you the advice and strategies you need to stay ahead and make the most of your IT investments.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="fbox-3 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                <div className="fbox-3-ico grey-color ico-65">
                  <span className="flaticon-user-2"></span>
                </div>
                <div className="fbox-3-txt">
                  <h5 className="h5-sm txt-700 white-color">IT Management Services</h5>
                  <p className="grey-color">
					Leave the tech headaches to us! We’ll handle everything from monitoring to support, keeping your systems running smoothly so you can focus on what matters most.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="fbox-3 pc-25 mb-40 wow fadeInUp" data-wow-delay="1s">
                <div className="fbox-3-ico grey-color ico-65">
                  <span className="flaticon-server-1"></span>
                </div>
                <div className="fbox-3-txt">
                  <h5 className="h5-sm txt-700 white-color">Cloud Computing Services</h5>
                  <p className="grey-color">
					Whether you're moving to the cloud or looking to improve your current cloud setup, we’ll help optimize your environment for better performance, scalability, and cost efficiency, ensuring it supports your business growth securely and seamlessly.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="fbox-3 pc-25 mb-40 wow fadeInUp" data-wow-delay="1.2s">
                <div className="fbox-3-ico grey-color ico-65">
                  <span className="flaticon-notification"></span>
                </div>
                <div className="fbox-3-txt">
                  <h5 className="h5-sm txt-700 deepblue-color">Push Notifications</h5>
                  <p className="grey-color">
                    Porta semper lacus cursus, feugiat primis ultrice and ligula risus auctor tempus feugiat at impedit felis undo auctor augue an egestas mauris
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="fbox-3 pc-25 mb-40 wow fadeInUp" data-wow-delay="1.4s">
                <div className="fbox-3-ico grey-color ico-65">
                  <span className="flaticon-block"></span>
                </div>
                <div className="fbox-3-txt">
                  <h5 className="h5-sm txt-700 deepblue-color">Concrete Security</h5>
                  <p className="grey-color">
                    Porta semper lacus cursus, feugiat primis ultrice and ligula risus auctor tempus feugiat at impedit felis undo auctor augue an egestas mauris
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
      
			<section id="download-6" className="bg-dark download-section division">
				<div className="container">
					<div className="row">
            
						<div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
							<div className="download-6-txt text-center">
                
								<h4 className="h4-md white-color">Have questions? Reach out to us at info@viniciti.com</h4>
                
								<div className="btns-group ">
									<a href="https://forms.gle/DTrBywNdGwLuNtkp8" className="btn btn-md btn-purple purple-hover">Get Started</a>
								</div>
							
							</div>
						</div>


					</div>
				</div>
			</section>
      

			<footer id="footer-2" className="footer division">
				<div className="container">
          
					<div className="row">
            
						<div className="col-md-3">
							<div className="footer-info mb-40">
                
								<img src="images/logo-sm.png" height="50" alt="footer-logo"/>
							
							</div>	
						</div>
            
						<div className="col-sm-4 col-md-3 col-xl-2">
							<div className="footer-links mb-40">
								<ul className="foo-links clearfix">
									<li><a href="mailto:info@viniciti.com">Help & Support</a></li>
									<li><a href="https://medium.com/@viniciti">Our Blog</a></li>
									<li><a href="https://viniciti.notion.site/ViniCiti-Careers-efed369b6976406f9e58d15702e8985d">Careers</a></li>
								</ul>
							</div>
						</div>
            
						<div className="col-sm-4 col-md-3 col-xl-2">
							<div className="footer-links mb-40">
								<ul className="foo-links clearfix">	
									<li><a href="https://viniciti.notion.site/ViniCiti-Privacy-Policy-5da749d7d66942899c96002b846898cd">Privacy Policy</a></li>
									<li><a href="https://viniciti.notion.site/ViniCiti-Terms-of-Use-de9307a118234e63916413cd4bd67a39">Terms</a></li>
									<li><a href="https://viniciti.notion.site/ViniCiti-Community-Guidelines-e8d990313d2b401a8c475df4c56727fd">Guidelines</a></li>
								</ul>
							</div>
						</div>
            
						<div className="col-sm-4 col-md-3 offset-xl-2">
							<div className="footer-store-badges text-right mb-40">
								
								<a href="https://forms.gle/DTrBywNdGwLuNtkp8" className="btn btn-dark dark-hover store">Get In Touch</a>
								<a href="https://calendly.com/viniciti/30min" className="btn btn-dark dark-hover store">Schedule A Call</a>
                
								{/* <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/viniciti/id1584689774" className="store">
									<img className="appstore-original" src="images/appstore.png" alt="appstore-badge" />
								</a>
                
								<a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.viniciti.viniciti" className="store">
									<img className="googleplay-original" src="images/googleplay.png" alt="googleplay-badge" />
								</a>  */}
                		
							</div>
						</div>


					</div>
          

					<div className="bottom-footer">
						<div className="row d-flex align-items-center">
              

							<div className="col-md-6">
								<div className="footer-copyright">
									<p>&copy; 2024 VINICITI. All Rights Reserved</p>
								</div>
							</div>
              

							<div className="col-md-6">
								<ul className="bottom-footer-list text-right clearfix">
									<li><a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/company/viniciti">LinkedIn</a></li>	
									<li><a target="_blank" rel="noopener noreferrer" href="https://x.com/vinicitiapp">X</a></li>	
									<li className="last-li"><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/viniciti">Instagram</a></li>
								</ul>
							</div>


						</div>
					</div>


				</div>								
			</footer>
      

		</div>
    </div>
  );
}

export default App;
